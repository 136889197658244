import { FC } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { getImage } from 'gatsby-plugin-image'
import { Layout } from 'src/components/site/Layout'
import { Breadcrumb } from 'src/components/groups/Breadcrumb'
import { Section } from 'src/components/parts/Section'
import { Container } from 'src/components/parts/Container'
import { PageHeading } from 'src/components/parts/PageHeading'
import { Paragraph } from 'src/components/parts/Paragraph'
import { SectionHeading } from 'src/components/parts/SectionHeading'
import { PanelGrid } from 'src/components/parts/PanelGrid'
import { Pagenation } from 'src/components/parts/Pagenation'
import { PostLink } from 'src/components/parts/PostLink'

type Props = {
  data: GatsbyTypes.AllPostsQuery
  pageContext: {
    previousPagePath: string
    nextPagePath: string
    pageNumber: number
    humanPageNumber: number
  }
}

const BlogCreatePage: FC<Props> = ({ data, pageContext }) => {
  const { previousPagePath, nextPagePath, humanPageNumber, pageNumber } =
    pageContext
  const sectionHeading = `${humanPageNumber}ページ目`
  const rootBreadcrumbItems = [
    {
      title: 'HOME',
      path: '/',
    },
    {
      title: 'BLOG',
      path: '/blog/',
    },
  ]

  const childBreadcrumbItems = [
    {
      title: 'HOME',
      path: '/',
    },
    {
      title: `BLOG`,
      path: '/blog/',
    },
    {
      title: `BLOG ${sectionHeading}`,
      path: `/blog/page/${humanPageNumber}/`,
    },
  ]

  const breadcrumbItems =
    pageNumber === 0 ? rootBreadcrumbItems : childBreadcrumbItems
  const useLd = pageNumber === 0 ? 'BlogPosting' : 'BlogPostingChild'

  return (
    <Layout
      title={`BLOG ${sectionHeading}`}
      description="しがないフリーランスエンジニアが地道に更新しています。EC運営やエンジニアの役に少しでも立つ情報を発信中です。"
      ldType={useLd}
    >
      <Helmet>
        {previousPagePath ? <link rel="prev" href={previousPagePath} /> : null}
        {nextPagePath ? <link rel="next" href={nextPagePath} /> : null}
      </Helmet>
      <Section>
        <Container>
          <PageHeading heading="BLOG" />
        </Container>
      </Section>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Section>
        <Container>
          <SectionHeading heading="記事一覧" subHeading={`${sectionHeading}`} />
          <PanelGrid>
            {data.allMdx.edges.map(({ node }) => {
              const posts = node.frontmatter
              // @ts-ignore
              const image = getImage(posts?.thumbnail)
              return (
                <PostLink
                  key={node.id}
                  slug={posts?.slug}
                  heading={posts?.title}
                  tag={posts?.tag}
                  image={image}
                />
              )
            })}
          </PanelGrid>
          <Paragraph>
            <Pagenation pageContext={pageContext} />
          </Paragraph>
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AllPosts($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          frontmatter {
            thumbnail {
              childImageSharp {
                gatsbyImageData
              }
            }
            slug
            tag
            title
          }
        }
      }
    }
  }
`
export default BlogCreatePage

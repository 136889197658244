import { FC, Fragment } from 'react'
import { Link } from 'gatsby'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { size, color } from 'src/utils/constants'

type Props = {
  pageContext: {
    previousPagePath: string
    nextPagePath: string
    pageNumber: number
    humanPageNumber: number
  }
}

export const Pagenation: FC<Props> = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <Fragment>
      {previousPagePath || nextPagePath ? (
        <div css={rootStyle}>
          {previousPagePath ? (
            previousPagePath === '/blog/' ? (
              <Link to={previousPagePath} css={prevLinkStyle}>
                前のページ
              </Link>
            ) : (
              <Link to={`${previousPagePath}/`} css={prevLinkStyle}>
                前のページ
              </Link>
            )
          ) : null}
          {nextPagePath ? (
            <Link to={`${nextPagePath}/`} css={nextLinkStyle}>
              次のページ
            </Link>
          ) : null}
        </div>
      ) : null}
    </Fragment>
  )
}

const rootStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 ${size.gutter.m}rem;
`

const linkStyle = css`
  display: block;
  max-width: max-content;
  position: relative;
  transition: color ${size.delay.s}s ease-out, border ${size.delay.s}s ease-out;
  border: ${size.border.s}rem dotted ${color.text};
  padding: 0.5rem;
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    margin: auto;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    transition: border ${size.delay.s}s ease-out;
  }
  &:hover,
  &:focus {
    color: ${color.shadow};
    border: ${size.border.s}rem dotted ${color.shadow};
  }
`

const prevLinkStyle = css`
  ${linkStyle};
  padding-left: ${size.gutter.s}rem;
  &::before {
    left: 0.5rem;
    border-right: 0.5rem solid ${color.text};
  }
  &:hover,
  &:focus {
    &::before {
      border-right: 0.5rem solid ${color.shadow};
    }
  }
`

const nextLinkStyle = css`
  ${linkStyle};
  padding-right: ${size.gutter.s}rem;
  &::before {
    right: 0.5rem;
    border-left: 0.5rem solid ${color.text};
  }
  &:hover,
  &:focus {
    &::before {
      border-left: 0.5rem solid ${color.shadow};
    }
  }
`
